.hero-wrapper,
	#content {
		.bean-hero-block {
			margin-top: -10px;
			position: relative;
			height: 440px;
			//background-size: cover;
			background-position: -450px -100px;
			@include media($laptop) {
				background-position: center top;
			}
			background-attachment: fixed;
		.content {
			@include outer-wrapper;
			}
			li {
				margin: 5px 0px;
				a {
					color: white;
					font-size: 2em;
					@include button-style-3;
				  @include media($highdef) {
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}


.preface-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}



#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				margin-top: 0.5em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
		@include media($mobile) {
			height: 340px;
			padding: 0em 1em;
			font-size: 90%;
		}
		@include media($laptop) {
			height: 300px;
			padding: 0em 1em;
			font-size: 120%;
		}
	}
}



