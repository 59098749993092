
.right {
  float: right;
}
.left {
  float: left;
}

.clear {
	clear: both;
}

.display-inline-block {
	display: inline-block;
}

.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-centre {
	text-align: center;
}
.horz-list {
  li {
    display: inline-block;
  }
}

.views-row {
	margin-bottom: 20px;
}

// Vertical space
.vert-space-10 {
	margin: 10px 0;
}

.vert-space-20 {
	margin: 20px 0;
}

.vert-space-30 {
	margin: 30px 0;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-30 {
  margin-top: 30px;
}
.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}
.padding-5 {
  padding: 5px 0;
}
.padding-50 {
  padding: 50px 0;
}
.npm {
	p {
		margin: 0;
	}
}

.rounded {
  border-radius: 5px;
}

@include media($tablet) {
  .two-column {
    @include columns(2);
    column-gap: 30px;
    p {
      break-inside: avoid;
    }
  }
}



