//////////////////////////////////////////////////////
// 1. Member content content type 
// 2. Member directory view and image swap
// 3. Member directory grid using Omega Reset
// 4. Member Directory Sorting
//////////////////////////////////////////////////////

// 1. Member content content type 

.node-person,
.page-profile-staff {
	.field-name-field-profile-image img {
		margin-left: 20px;
		@include border;
		@include boxshadow;
	}
	.field-name-field-position {
		margin-bottom: 20px;
	}
}


.node-person,
.page-profile-staff {
	.field-field-profile-image-wrapper {
		position: relative;

		.field-name-field-profile-image:nth-child(1) { 
			float: right;
			z-index: 0;
		}
		.field-name-field-profile-image:nth-child(2) { 
			position: absolute;
			top: 0px;
			right: 0px;
			z-index: 1;
		}
	}
}

//////////////////////////////////////////////////////

// 2. Member directory view and image swap

.member-directory {
	overflow: auto;
	//display: block;
	.member-row-wrapper {
		position: relative;
		h3 {
			display: none;
		}
		.field-name-field-profile-image img {
			@include border;
			@include boxshadow;
		}
		.field-name-field-profile-image:nth-child(2) { 
			position: absolute;
			top: 10px;
			left: 0px;
			@include transition(all 0.25s ease-in-out);
			&:hover {
				opacity: 0;
				@include transition(0.25s ease-in);
			}
		}
	}
}

.member-directory-associates {
	//overflow: auto;
	//display: block;
	.member-row-wrapper {
		position: relative;
		h3 {
			display: none;
		}
		.field-name-field-profile-image img {
			@include border;
			@include boxshadow;
			margin: 0;
		}
		.field-name-field-profile-image:nth-child(2) { 
			position: absolute;
			top: 0px;
			left: 0px;
			@include transition(all 0.25s ease-in-out);
			&:hover {
				opacity: 0;
				@include transition(0.25s ease-in);
			}
		}
	}
}

////////////////////////////////////////////////////// 

// 3. Member directory grid using Omega Reset

.no-sidebars {
	.member-row {
		@include media(300px)  {
		@include span-columns(6);
		@include omega(2n);
		}
		@include media(480px)  {
		@include omega-reset(2n);
		@include span-columns(4);
		@include omega(3n);
		}
		@include media(700px) {
		@include omega-reset(3n);
		@include span-columns(3);
		@include omega(4n);
		}
		@include media(950px) {
		@include omega-reset(4n);
		@include span-columns(2);
		@include omega(6n);
		}
	}
}
.one-sidebar {
	.member-row {
		@include media(300px)  {
		@include span-columns(6);
		@include omega(2n);
		}
		@include media(480px)  {
		@include omega-reset(2n);
		@include span-columns(4);
		@include omega(3n);
		}
		@include media(700px) {
		@include omega-reset(2n);
		@include span-columns(4);
		@include omega(3n);
		}
		@include media(950px) {
		@include omega-reset(3n);
		@include span-columns(3);
		@include omega(4n);
		}
	}
}

.two-sidebars {
	.member-row {
		@include media(300px)  {
		@include span-columns(6);
		@include omega(2n);
		}
		@include media(480px)  {
		@include omega-reset(2n);
		@include span-columns(4);
		@include omega(3n);
		}
		@include media(600px) {
		@include omega-reset(2n);
		@include span-columns(6);
		@include omega(2n);
		}
		@include media(950px) {
		@include omega-reset(2n);
		@include span-columns(4);
		@include omega(3n);
		}
	}
}


//////////////////////////////////////////////////////

// 4. Member Directory Sorting


.associate-directory-sorting,
.staff-directory-sorting {
	position: relative;
	.view-header {
		margin-top: 20px;
		margin-bottom: 10px;
		border: 1px solid grey;
		background: whitesmoke;
		padding: 10px 10px 0px;
	}
	
	li.views-row {
		//max-width: 16%;
		border: 0;
		padding:0;
		margin: 10px !important;
	}
	.member-row {
		position: relative;
	}
	ul {
		margin-left: -10px;
	}
	.field-name-field-profile-image img {
		@include border;
	}
	.field-name-field-profile-image:nth-child(2) img { 
		@include border;
		position: absolute;
		top: 0px;
		left: 0px;
	}
	#edit-actions {
		clear: left;
	}
}


// 5. Profile 2 
//////////////////////////////////////////////////////

.view-staff-profiles {
	h2 a {
		display: none;
	}
	img {

	}
}




