//////////////////////////////////////////////////////////
// 1. Photo galleries
// 2. Photo gallery view
// 3. Photo gallery view sorting
//////////////////////////////////////////////////////////

// 1. Photo galleries

.field-name-field-gallery-image {
	margin-top: 30px;
}

.gallery-view {
	margin-top: 20px;
}




.no-sidebars {
	ul.gallery-tile {
		padding: 0;
		overflow: auto;
		@include imgzoom;
		li {
			overflow: hidden;
			display: inline-block;
			margin-bottom: 4%;
			@include boxshadow;
			@include media($mm-mobile) {
				@include span-columns(6);
				@include omega(2n);
			}
			@include media($mm-phablet) {
				@include span-columns(4);
				@include omega(3n);
			}
			@include media($mm-tablet) {
				@include span-columns(3);
				@include omega(4n);
			}
			@include media($laptop) {
				@include span-columns(2);
				@include omega(6n);
			}
		}
		img {
			height: auto;
			@include border;
			margin-bottom: -1px;
		}
	}
}

.one-sidebar {
	ul.gallery-tile {
		padding: 0;
		overflow: auto;
		@include imgzoom;
		li {
			display: inline-block;
			margin-bottom: 4%;
			@include boxshadow;
			@include media($mm-mobile) {
				@include span-columns(6);
				@include omega(2n);
			}
			@include media($mm-phablet) {
				@include span-columns(4);
				@include omega(3n);
			}
			@include media($tablet) {
				@include span-columns(2 of 8);
				@include omega(4n);
			}
		}
		img {
			height: auto;
			@include border;
			margin-bottom: -1px;
		}
	}
}


.two-sidebars {
	ul.gallery-tile {
		padding: 0;
		overflow: auto;
		@include imgzoom;
		li {
			display: inline-block;
			margin-bottom: 4%;
			@include boxshadow;
			@include media($mm-mobile) {
				@include span-columns(6);
				@include omega(2n);
			}
			@include media($mm-phablet) {
				@include span-columns(4);
				@include omega(3n);
			}
			@include media($mm-tablet) {
				@include span-columns(3 of 6);
				@include omega(2n);
			}
			@include media($laptop) {
				@include span-columns(2 of 6);
				@include omega(3n);
			}
		}
		img {
			height: auto;
			@include border;
			margin-bottom: -1px;
		}
	}
}

//////////////////////////////////////////////////////////

// 2. Photo gallery view

.gallery-view-image {
	overflow: hidden;
	line-height: 0;
	@include boxshadow;
	margin-bottom: 10px;
}

.no-sidebars {
	.gallery-view {
		.gallery-tile {
			@include imgzoom;
			display: inline-block;
			@include media($mm-mobile) {
				@include span-columns(6);
				@include omega(2n);
			}
			@include media($mm-phablet) {
				@include span-columns(6);
				@include omega(2n);
			}
			@include media($mm-tablet) {
				@include span-columns(4);
				@include omega(3n);
			}
			@include media($laptop) {
				@include span-columns(2);
				@include omega(6n);
			}

		}
		img {
			height: auto;
			@include border;
			margin-bottom: -1px;

		}
	}
}

.one-sidebar {
	.gallery-view {
		.gallery-tile {
			display: inline-block;
			@include span-columns(2 of 8);
			@include omega(4n);
		}
		img {
			@include boxshadow;
			@include border;
			height: auto;
			margin-bottom: -1px;
		}
	}
}





//////////////////////////////////////////////////////////

// 3. Photo gallery view sorting


.page-photo-gallery-sorting {
	h1 {
		margin-top: 20px;
	}
	.view-header {
		margin-top: 20px;
		margin-bottom: 10px;
		border: 1px solid grey;
		background: beige;
		padding: 10px 10px 0px;
	}
	ul {
		margin-left: -10px;
	}
}


//////////////////////////////////////////////////////////

.recent-images-block {
	overflow: auto;
	margin-bottom: 20px;
	ul {
		margin-left: -1%;
	}

	li {
		overflow: hidden;
		line-height: 0em;
		@include imgzoom;
		@include boxshadow;
	}
	.recent-images-row {
		display: inline;
		float: left;
		width: 31.33333%;
		margin: 1%;
		img {
			height: auto;

		}
	}
}


