

.logo-block img {
  margin-top: 20px;
  margin-left: -14px;
}

.header-contact-details {
  letter-spacing: 0.08em;
  h4 {
    line-height: 1.4em;
    margin: 0;
    strong {
      color: $orange;
    }
  }
}

.navigation-wrapper {
  background: $orange;
}

.front-page-hero {
  margin-top: 10px;
  text-align: center;
  p {
    margin: 0;
  }
  h2 {
    color: white;
    font-weight: 700;
    @include media($mobile) {
      font-size: 2em;
      padding: 0.5em 0 0.1em;
    }
    @include media($tablet) {
      font-size: 3em;
      padding: 0.6em 0 0.1em;
    }
    @include media($highdef) {
      font-size: 3em;
      padding: 0.6em 0 1.5em;
    }
  }
  ul{
    list-style-type: none;
    padding: 0 10px;
    margin-top: 10px!important;
    font-size: 1.8em;
  }
}

.baxandall-hero {
  background-attachment: scr;
}

.front-page-intro h2 {
  color: $orange;
}

.preface-right {
  padding-bottom: 20px;
  .button-style-2 a {
    margin-top: 20px;
    font-size: 1.3em;
    padding: 1em 2em;
  }

}


.front {
  #row1 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background-image: url("../images/row-one-img.jpg");
    background-size: cover;
    background-position: center;
    h2 {
      color: white;
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 2px 2px 6px $charcoal;
    }
    .field-name-field-block-text {
      font-weight: 300;
      text-align: center;
      color: white;
      text-shadow: 2px 2px 6px $charcoal;
    }
    @include media($laptop) {
      h2 {
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  #row2 {
    padding: 70px 14px;
    background: white;
    min-height: 300px;
    p {
      font-size: 19px;
    }
    img[style*="right"] {
      margin: 8px 0 10px 40px;
    }
  }
  #row3 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background-image: url("../images/row-three-img.jpg");
    background-size: cover;
    background-position: center;
    h2 {
      color: white;
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 2px 2px 6px $charcoal;
    }
    .field-name-field-block-text   {
        font-weight: 300;
        text-align: center;
        color: white;
        font-size: 1em;
        text-shadow: 2px 2px 6px $charcoal;
    }
    @include media($laptop) {
      h2 {
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  #row4 {
    padding: 70px 14px;
    background: white;
    p {
      font-size: 19px;
    }
    img[style*="left"] {
      margin: 8px 40px 10px 0;
    }
  }
  #row5 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background-image: url("../images/row-five-img.jpg");
    background-size: cover;
    background-position: center;
    h2 {
      color: white;
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 2px 2px 6px $charcoal;
    }
    .field-name-field-block-text   {
      font-weight: 300;
        text-align: center;
        color: white;
        font-size: 1em;
        text-shadow: 2px 2px 6px $charcoal;
    }
    @include media($laptop) {
      h2 {
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  #row6 {
    padding: 70px 14px;
    background: white;
    p {
      font-size: 19px;
    }
    img[style*="left"] {
      margin: 8px 40px 10px 0;
    }
  }
}

.page-node main {
  margin-top: 40px;
}

.bold-header h2 {
  font-weight: 700;
  font-size: 1.562em;
  @include media($laptop) {
    font-size: 2em;
  }
  @include media($desktop) {
    font-size: 2.929em;
  }
}

.phone-icon {
  display: block;
  position: fixed;
  bottom: 5px;
  right: 11px;
  z-index: 9999;
  @include media($laptop) {
    display: none;
  }
}

#site-footer {
  position: relative;
  color: white;
  a {
    color: white;
  }
  .easable-credit {
    @include media($laptop) {
      position: absolute;
      bottom: 10px;
      right: 0px;
    }
  }
}


.baxandall-history img {
  margin-top: -19px ;
}


