
.field-field-attachments-wrapper,
.field-field-links-wrapper,
.field-field-private-files-wrapper,
.field-og-vocabulary-wrapper {
	h3 {
		padding: 10px;
		font-size: 1em;
		margin: 20px 0 0 0;
		text-transform: uppercase;
		font-weight: 700;
		color: white;
		line-height: 1em;
		@include border;
		border-bottom: 0;
		@include border-top-radius(5px);
		display: inline-block;
		min-width: 180px;
		background: $teal;
	}
}

.field-border,
.node-type-og-group-post .field-name-field-private-files,
.node-type-og-group .field-name-field-private-files,
.field-name-og-vocabulary ul,
.field-name-field-attachments,
.field-name-og-vocabulary  {
	@include border;
	@include boxshadow;
	-webkit-border-radius: 0px 5px 5px 5px;
	        border-radius: 0px 5px 5px 5px;
	padding: 10px;
	margin: 0 0 20px 0;
	list-style-type: none;
	clear: both;
}
.field-name-field-private-files li,
.field-name-field-attachments li {
	//display: inline-block;
	margin-right: 10px;
}

// Tags
#content {
	.field-type-taxonomy-term-reference {
		display: inline-block;
		padding-right: 20px;
	}
}

.field-name-og-vocabulary {
	margin-left: 0;

}

.field-name-og-vocabulary ul li {
	display: inline-block;
	margin: 0 20px 0 0;
}
