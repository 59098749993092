
.section-view {
	overflow: auto;
}

.section-box {
	background: $section-box-bg-color;
	margin-bottom: 4%;
}

.no-sidebars {
	.section-box {
		@include span-columns(12);
	}
}

.one-sidebar {
	.section-box {
		@include span-columns(12);
	}
}

.two-sidebars {
	.section-box {
		@include span-columns(12);
	}
}

////////////////////////////////////////////////////////////

@include media(300px) {
	.no-sidebars {
		.section-box {
			@include span-columns(12);
			@include omega(1n);
		}
	}
}

@include media(450px) {
	.no-sidebars {
		.section-box {
			@include omega-reset(1n);
			@include span-columns(6);
			@include omega(2n);
		}
	}

	.one-sidebar {
		.section-box {
			@include span-columns(4 of 8);
			@include omega(2n);
		}
	}
}

@include media(900px) {
	.no-sidebars {
		.section-box {
			@include omega-reset(2n);
			@include span-columns(4);
			@include omega(3n);
		}
	}

	.one-sidebar {
		.section-box {
			@include span-columns(4 of 8);
			@include omega(2n);
		}
	}
}
////////////////////////////////////////////////////////////

.section-eva {
	overflow: auto;
}



.section-box-sorting { //Sorting grid tweaks
	padding: 0;
	img {
		height: auto;
	}
	ul {
		margin-left: -10px;
	}
	.view-header {
		margin-top: 20px;
		margin-bottom: 10px;
		border: 1px solid grey;
		background: whitesmoke;
		padding: 10px 10px 0px;
	}
	.section-box {
		margin-bottom: 3%;
		@include boxshadow;
		margin-left: 0;
		padding: 0px !important;
		li {
			margin: 0;
		}
		h2,
		.field-name-body  {
			padding: 0 10px;
		}
	}
	.section-box:hover {
		cursor: pointer;
	}
	#edit-actions {
		clear: left;
	}

	.draggableviews-processed li.views-row {
		width: 45%;
	}
}

.one-sidebar {
	.section-box-sorting {
		.draggableviews-processed li.views-row {
			width: 48%;
		}
	}
}

.no-sidebars {
	.section-box-sorting {
		.draggableviews-processed li.views-row {
			width: 30%;
		}
	}
}

////////////////////////////////////////////////////////////



