table {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $base-accent-color: #477DCA !default;
  $base-link-color: $base-accent-color !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;

  width: 100% !important;

  th {
    text-align: left;
  }

  td {
    line-height: $base-line-height;
  }
  //////////////////////////////////////////////////////////////////////////////////

  $table-border-color: $base-border-color;
  $table-border: 1px solid $table-border-color;
  $table-background: $base-background-color;
  //$table-header-background: lighten($table-background, 10);
  $table-header-background: whitesmoke;
  $table-hover-background: darken($table-background, 1);
  $table-stripe-background: darken($table-background, 4);
  $table-stripe-background-hover: darken($table-stripe-background, 5);
  $table-padding: .75em 0.5em;

  //border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr:hover > td, tr:hover > th {
      background-color: $table-hover-background;
    }
    tr:first-child td {
      border-top: 1px solid $table-border-color;
    }
  }

  thead {
    th {
      background-color: $table-header-background;
      border-bottom: 0px;
      padding: $table-padding;
    }
  }

  tbody {
    background-color: $table-background;

    td {
      border-bottom: 0px;
      border-top: 1px solid $table-border-color;
      padding: $table-padding;
    }
  }
}
