////////////////////////////////////////////////////////

// Include viewport rule. Ref: http://bit.ly/1EJ8P2P

//@viewport {
//    zoom: 1.0;
//    width: extend-to-zoom;
//}

@-ms-viewport{
    width: extend-to-zoom;
    zoom: 1.0;
}


////////////////////////////////////////////////////////

// Default page structure

@include media($laptop) { // Set layout for devices of 600px size and above
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    main {
      @include outer-container;
    }

      &.no-sidebars {
        main {
          section#content {
            @include span-columns(12);
          }
        }
      }

      &.one-sidebar.sidebar-first {
        main {
          aside {
            &#sidebar-first {
              @include span-columns(4);
            }
          }

          section#content {
            @include span-columns(8);
          }
        }
      }

      &.one-sidebar.sidebar-second {
        main {
          aside {
            &#sidebar-second {
              @include span-columns(4);
            }
          }

          section#content {
            @include span-columns(8);
          }
        }
      }

      &.two-sidebars {
        main {
          aside {
            &#sidebar-first {
              @include span-columns(2);
            }

            &#sidebar-second {
              @include span-columns(4);
            }
          }

          section#content {
            @include span-columns(6);
          }
        }
      }
    footer {
      @include outer-container;
        .footer-one {
          @include span-columns(6);
        }
        .footer-two {
          @include span-columns(6);
        }
      }
    }
  }


